<template>
	<div class="app-box">
		<div class="title-box">
			<div class="line"></div>
			<p class="title">用户管理</p>
		</div>
		<a-divider style="margin-left: -10px" />
		<a-form-model class="form-search" :colon="false" layout="inline" :model="query" labelAlign="left"
			@submit="handleSearch" @submit.native.prevent>
			<a-row>
				<a-form-model-item style="float: right; margin-right: 0">
					<a-button type="primary" html-type="submit"> 查询 </a-button>
					<a-button @click="Reset">重置</a-button>
				</a-form-model-item>
				<a-form-model-item label="套餐">
					<a-select placeholder="请选择套餐" v-model="query.package_id">
						<a-select-option :value="item.package_id" v-for="item in levelList" :key="item.package_id">
							{{ item.name }}
						</a-select-option>
					</a-select>
				</a-form-model-item>
				<a-form-model-item label="手机号">
					<a-input v-model="query.phone" placeholder="请输入手机号"></a-input>
				</a-form-model-item>
				<a-form-model-item label="姓名">
					<a-input v-model="query.name" placeholder="请输入姓名"></a-input>
				</a-form-model-item>
				<a-form-model-item label="状态">
					<a-select placeholder="请选择" v-model="query.up_status">
						<a-select-option :value="1" :key="1">
							未上传
						</a-select-option>
						<a-select-option :value="2" :key="2">
							已上传
						</a-select-option>
					</a-select>
				</a-form-model-item>
			</a-row>
		</a-form-model>
		<a-table class="main-table" :pagination="{
			current: query.page,
			total: pagetotal,
			showTotal: (pagetotal, range) => `共 ${pagetotal} 条数据`,
		}" @change="listChange" :columns="columns" :data-source="data" rowKey="customer_wenjuan_id" style="margin-top: 14px"
			v-viewer>
			<span slot="wenjuan" slot-scope="text, record">
				健康问卷
			</span>
			<span slot="up_status" slot-scope="text, record">
				{{ record.up_status == 2 ? '已上传' : '未上传' }}
			</span>
			<span slot="action" slot-scope="text, record">
				<a-space>
					<a @click="toEdit(record)">查看</a>
					<a @click="reportUpload(record)">上传报告</a>
				</a-space>
			</span>
		</a-table>

		<!-- 抽屉1 添加/修改 -->
		<a-drawer title="上传报告" :width="750" :visible="visible1" :body-style="{ paddingBottom: '80px' }" @close="onClose1">
			<a-form-model ref="ruleForm" :model="customerForm" :rules="rules" :label-col="labelCol"
				:wrapper-col="wrapperCol">
				<a-form-model-item prop="name" label="健康档案">
					<a-input v-model="customerForm.name" style="width: 280px;"
						placeholder="支持扩展名: .rar .zip .docdocx .pdf jpg.." disabled>
					</a-input>
					<a-upload style="margin-left: 10px" :multiple="false" :beforeUpload="beforeUpload"
						:showUploadList="false" :headers="{
							Authorization: 'Bearer ' + token
						}" @change="handleChange" :action="$domain + '/admin/v1/index/appendixUpload'">
						<a-button> <a-icon type="upload" /> 上传文件 </a-button>
					</a-upload>
				</a-form-model-item>
			</a-form-model>
			<div :style="{
				position: 'absolute',
				right: 0,
				bottom: 0,
				width: '100%',
				borderTop: '1px solid #e9e9e9',
				padding: '10px 16px',
				background: '#fff',
				textAlign: 'right',
				zIndex: 1,
			}
				">
				<a-button :style="{ marginRight: '8px' }" @click="onClose1">
					取消
				</a-button>
				<a-button type="primary" @click="submitForm">
					确定
				</a-button>
			</div>
		</a-drawer>
		<a-drawer title="问卷" :width="550" :visible="visible4" :body-style="{ paddingBottom: '80px' }"
			@close="visible4 = false">
			<!-- <a-divider /> -->
			<div class="ques-box">
				<div class="ques-item" v-for="item in answer" :key="item.questionId">
					<div class="question">{{ item.question.label }}</div>
					<div v-if="item.question.type === 'radio'" class="radio-group">
						<div class="radio" v-for="(i, j) in item.question.options" :key="j"
							:class="{ checked: item.answer === '' + j }">
							{{ i.label }}
						</div>
					</div>
					<div v-if="item.question.type === 'checkbox'" class="radio-group">
						<div class="radio" v-for="(i, j) in item.question.options" :key="j"
							:class="{ checked: item.answer.includes('' + j) }">
							{{ i.label }}
						</div>
					</div>
					<div v-if="item.question.type === 'input'" class="radio checked">{{ item.answer }}</div>
				</div>
			</div>
			<div :style="{
				position: 'absolute',
				right: 0,
				bottom: 0,
				width: '100%',
				borderTop: '1px solid #e9e9e9',
				padding: '10px 16px',
				background: '#fff',
				textAlign: 'right',
				zIndex: 1,
			}
				">
				<a-button :style="{ marginRight: '8px' }" @click="visible4 = false">
					取消
				</a-button>
				<a-button type="primary" @click="visible4 = false">
					确定
				</a-button>
			</div>
		</a-drawer>
	</div>
</template>

<script>
import { domain } from '@/siteInfo.js'
import question from '@/utils/基础问卷调查题目'

export default {
	data() {
		let validateClass = (rule, value, callback) => {
			if (value) {
				callback(); // 通过验证
			} else {
				callback(new Error('请选择分类')); // 验证失败，返回错误信息
			}
		};
		return {
			query: {
				page: 1,
				limit: 10
			},
			customer_wenjuan_id: null,
			pagetotal: 0,
			visible1: false,
			visible4: false,
			labelCol: { span: 4 },
			wrapperCol: { span: 14 },
			other: '',
			customerForm: {
				name: '',
				url: ''
			},
			curParent: [],
			rules: {
				name: [
					{ required: true, message: '请上传文件', trigger: 'blur' },
				],
			},
			answer: [],
			columns: [
				{
					title: '姓名',
					dataIndex: 'name'
				},
				{
					title: '手机号',
					dataIndex: 'phone'
				},
				{
					title: '套餐名称',
					dataIndex: 'package_name'
				},
				{
					title: '问卷',
					scopedSlots: {
						customRender: 'wenjuan'
					}
				},
				{
					title: '状态',
					scopedSlots: {
						customRender: 'up_status'
					}
				},
				{
					title: '提交时间',
					dataIndex: 'create_at_str'
				},
				{
					title: '操作',
					scopedSlots: {
						customRender: 'action',
					},
				},
			],
			data: [],
			levelList: [],
			selectedRowKeys: []
		}
	},
	created() {
		this.getList()
		this.getLevelList()
	},
	methods: {
		beforeUpload(file) {
			this.file = file
			this.uping = true
			this.spinning = true
			console.log('token', this.token)
			console.log('before', file)
		},
		handleChange(e) {
			console.log('eeee', e.file.name)
			console.log('token', this.token)
			console.log('改变。。。')
			if (e.file.status == 'done') {
				console.log(e.file.response)
				this.spinning = false
				this.uping = false
				let { msg, data, code } = e.file.response
				if (code == 0) {
					this.customerForm.url = data.really_path
					this.customerForm.name = e.file.name
					console.log("现在的form对象", this.form)
				} else {
					this.$message.warn(msg)
				}
			}
		},
		reportUpload(record) {
			this.$post(domain + '/admin/v1/wenjuan/recordObj', { "customer_wenjuan_id": record.customer_wenjuan_id }).then(res => {
				if (res.code === 0) {
					console.log("看看没有赋值过的是怎么样的", res.data)
					console.log("看看没有赋值过的是怎么样的", res.data.record_obj)
					if (res.data.record_obj == null || res.data.record_obj == "") {
						this.customerForm = {
							name: '',
							url: '',
						}
					} else {
						this.customerForm = res.data.record_obj
					}
				} else {
					this.$message.error(res.msg)
				}
			})
			this.visible1 = true
			this.customer_wenjuan_id = record.customer_wenjuan_id
		},
		submitForm() {
			let obj = {
				customer_wenjuan_id: this.customer_wenjuan_id,
				record_obj: this.customerForm
			}
			this.$post(domain + '/admin/v1/wenjuan/uploadFile', obj).then(res => {
				if (res.code === 0) {
					this.$message.success(res.msg)
					this.visible1 = false
					this.initForm()
					this.getList()
				} else {
					this.$message.error(res.msg)
				}
			})
		},
		Reset() {
			this.query = {
				page: 1,
				limit: this.query.limit
			}
			this.getList()
		},
		getName(arr, id, idName, lableName) { //通过id获取数组名称
			if (id || id == '0') {
				const index = arr.findIndex((role) => role[idName] === id)
				return index >= 0 ? arr[index][lableName] : undefined
			} else {
				return undefined
			}
		},
		toEdit(record) {
			this.$post(domain + '/admin/v1/wenjuan/info', { customer_wenjuan_id: record.customer_wenjuan_id }).then(res => {
				if (res.code === 0) {
					res.data.answer.map(item => {
						item.question = question.find(i => i.questionId === item.questionId)
					})
					this.answer = res.data.answer
					this.visible4 = true
				} else {
					this.$message.error(res.msg)
				}
			})
		},
		onClose1() {
			this.visible1 = false;
			this.initForm()
		},
		initForm() {
			this.customer_wenjuan_id = null
			this.customerForm = {
				name: '',
				url: '',
			}
		},
		getList() {
			this.$post(domain + '/admin/v1/wenjuan/list', this.query).then(res => {
				if (res.code === 0) {
					this.data = res.data.list
					this.pagetotal = res.data.total
				} else {
					this.$message.error(res.msg)
				}
			})
		},

		getLevelList() {
			this.$post(domain + '/admin/v1/package/listNoPage').then(res => {
				if (res.code === 0) {
					this.levelList = res.data
				} else {
					this.$message.error(res.msg)
				}
			})
		},
		listChange(e) {
			this.query.page = e.current
			this.getList()
		},
		handleSearch(query) {
			this.query.page = 1
			this.customer_id = null
			this.getList()
			console.log(query)
		}
	}
}
</script>

<style lang="less" scoped>
.title-box {
	display: flex;
	align-items: center;
	margin-left: 8px;

	.line {
		width: 5px;
		height: 14px;
		background-color: #4e80f8;
	}

	.title {
		color: #383f50;
		font-size: 16px;
		margin-left: 5px;
	}
}

.img-qr {
	height: 30px;
	cursor: pointer;
}
</style>

<style lang="less">
.form-search {
	.ant-form-item-label {
		width: 70px;
	}
}

.ques-box {
	.question {
		font-weight: bold;
		margin-bottom: 10px;
	}

	.radio-group {
		display: flex;
	}

	.radio {
		margin-right: 10px;
		margin-bottom: 10px;

		&.checked {
			color: #1677FF;
		}
	}
}
</style>
